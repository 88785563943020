import { DateTime } from "luxon";
import { IClientInfo } from "./client.model";
import { IAuthorizedPeople, IChildren } from "@app/feature-genitore/genitore.model";

export interface IUserActivityInfo {
    created_at?: DateTime;
    user_create_id?: number;
    updated_at?: DateTime;
    user_update_id?: number;
}

export interface IUser extends IUserActivityInfo {
    id: number;
    name: string;
    surname: string;
    user_type: string;
    email: string;
    email_verified_at: string | null;
    two_factor_recovery_codes?: string | null;
    two_factor_secret?: string | null;
    info?: IClientInfo;
    children?: IChildren[];
    authorized_people?: IAuthorizedPeople[];
}
export const EMPTY_USER : IUser = {
    id: 0,
    name: '',
    surname: '',
    user_type: 'client',
    email: '',
    email_verified_at: null,
    created_at: DateTime.now(),
    updated_at: DateTime.now()
};
