import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { catchError, EMPTY, Observable, tap, throwError } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { LoggerService } from '../common-services/logger.service';
import { AuthGuard } from './auth-guard.service';
import { Router } from '@angular/router';

@Injectable()
export class UnAuthenticatedInterceptor implements HttpInterceptor {

    constructor(private authenticationService: AuthenticationService,
        private authGuard: AuthGuard,
        private logger: LoggerService,
        private router: Router) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401) 
                {
                    this.logger.warning('Intercept a 401.');
                    this.authenticationService.clearLoggedUser();
                    this.router.navigateByUrl('/login');
                    return EMPTY;
                }
                return throwError(() => error);
            })
        );
    }
}
