import { BehaviorSubject, Observable } from "rxjs";

export class ActionBehaviorSubject<T> {
    private action: BehaviorSubject<T>;
    action$: Observable<T>;

    constructor(initialValue: T) {
        this.action = new BehaviorSubject<T>(initialValue);
        this.action$ = this.action.asObservable();    
    }

    set(x: T): void { 
        this.action.next(x);
    }
    get() {
        return this.action.value;
    }
}