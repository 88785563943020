import { inject, NgModule } from '@angular/core';
import { Route, RouterModule, Routes, UrlSegment } from '@angular/router';
import { AuthGuard } from './auth/auth-guard.service';
import { LoginComponent } from './auth/login/login.component';
import { Error404Component } from './common-utilities/error-404.component';
import { TwoFAComponent } from './auth/login/two-fa.component';
import { ForgotPwdComponent } from './auth/login/forgot-pwd.component';
import { ResetPwdComponent } from './auth/login/reset-pwd.component';
import { RegisterComponent } from './auth/login/register.component';

const routes: Routes = [
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'login2',
        component: TwoFAComponent
    },
    {
        path: 'forgot-password',
        component: ForgotPwdComponent
    },
    {
        path: 'register',
        component: RegisterComponent
    },    
    {
        path: 'reset-password/:token',
        component: ResetPwdComponent
    },
    { 
        path: '', 
        canMatch: [(route: Route, segments: UrlSegment[]) => inject(AuthGuard).isLoggedIn(route, segments)],
        loadChildren: () => import('./main/main.module').then(m => m.MainModule) 
    },
    {
        path: '**',
        component: Error404Component
    }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
