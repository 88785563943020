
export const test_users: any[] = [
    {
        id: 1,
        name: 'Marco',
        surname: 'Bozzo',
        email: 'marco.bozzo@gmail.com',
        password: '!2345Sei',
        user_type: 'super-admin',
    },
    {
        id: 47,
        name: 'Valeria',
        surname: 'Palombo',
        email: 'ciccivespa@gmail.com',
        password: '12345Sei!',
        user_type: 'admin',
    },
    {
        id: 3,
        name: 'Collaborator1',
        surname: 'Collaborator1',
        email: 'smilevil2.onlus@gmail.com',
        password: '!2345Sei',
        user_type: 'collaborator',
    },
    {
        id: 4,
        name: 'NomeG1',
        surname: 'CognomeG1',
        email: 'genitore1@pincopallo.com',
        password: '123456PwD!',
        user_type: 'client',
    },
    {
        id: 5,
        name: 'NomeG2',
        surname: 'CognomeG2',
        email: 'genitore2@pincopallo.com',
        password: '!2345sei',
        user_type: 'client',
    },
    {
        id: 6,
        name: 'NomeG3',
        surname: 'CognomeG3',
        email: 'genitore3@pincopallo.com',
        password: '!2345sei',
        user_type: 'client',
    },
    {
        id: 24,
        name: 'Barbara',
        surname: 'Murialdo',
        email: 'barbara.murialdo@outlook.it',
        password: '!2345sei',
        user_type: 'client',
    }
];