<div style="max-width: 480px;" class="p-5 mx-auto text-center">
    
    <img class="mt-5" src="assets/images/logo.png" alt="logo" width="200">
    <p class="mat-h2">
        RESET PASSWORD
    </p>
        
    <div *ngIf="(isPasswordChanged.action$ | async) === true;then showInstructions else showForms"></div>
    
    <ng-template #showInstructions>
        <div class="alert alert-success mt-4">
            La password &egrave; stata modificata.
        </div>
    </ng-template>

    <ng-template #showForms>
        <form [formGroup]="formData" autocomplete="off" novalidate class="mt-4">
            <mat-form-field class="control-full-width" appearance="outline">
                <mat-label>Inserisci la tua email</mat-label>
                <input inputRefTrimValue matInput type="text" placeholder="pat@example.com" formControlName="email" required>
                <mat-icon matSuffix>email</mat-icon>
                <mat-error *ngIf="formData.controls['email'].errors?.['required']">Inserire email</mat-error>
                <mat-error *ngIf="formData.controls['email'].errors?.['email']">&apos;{{formData.controls['email'].value}}&apos; non &egrave; una email valida</mat-error>
                <mat-error *ngIf="formData.controls['email'].errors?.['maxLength']">Email troppo lunga</mat-error>
            </mat-form-field>

            <div formGroupName="passwordGroup" class="d-flex flex-column">
                <mat-form-field class="control-full-width">
                    <mat-label>Password</mat-label>
                    <input inputRefTrimValue type="password" matInput formControlName="password" required autocomplete="new-password">
                    <mat-error *ngIf="passwordGroup.controls['password'].errors?.['required']">
                        Inserire password</mat-error>                    
                </mat-form-field>
    
                <div *ngIf="pwd_status$ | async as pwd_status" class="mt-1">
                    <div class="row">
                        <div class="col text-start">lunghezza tra 8 e 16 caratteri</div>
                        <div class="col-auto">
                            <mat-icon [ngStyle]="{'color': (pwd_status.min && pwd_status.max) ? 'green' : 'red' }">
                                {{ (pwd_status.min && pwd_status.max) ? 'check_circle' : 'cancel' }}
                            </mat-icon>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col text-start">almeno 1 lettera maiuscola</div>
                        <div class="col-auto">
                            <mat-icon [ngStyle]="{'color': pwd_status.low ? 'green' : 'red' }">{{ pwd_status.low ? 'check_circle' : 'cancel' }}</mat-icon>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col text-start">almeno 1 lettera minuscola</div>
                        <div class="col-auto">
                            <mat-icon [ngStyle]="{'color': pwd_status.upp ? 'green' : 'red' }">{{ pwd_status.upp ? 'check_circle' : 'cancel' }}</mat-icon>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col text-start">almeno 1 numero</div>
                        <div class="col-auto">
                            <mat-icon [ngStyle]="{'color': pwd_status.num ? 'green' : 'red' }">{{ pwd_status.num ? 'check_circle' : 'cancel' }}</mat-icon>
                        </div>
                    </div>
                </div>
    
                <mat-form-field class="mt-2 control-full-width">
                    <mat-label>Ripeti password</mat-label>
                    <input inputRefTrimValue type="password" matInput formControlName="password_confirmation" [errorStateMatcher]="invalidParentErrorMatcher" required autocomplete="new-password">                
                    <mat-error *ngIf="passwordGroup.hasError('match')">
                        Le password sono diverse</mat-error>
                </mat-form-field>
            </div>
            
        </form>

        <button mat-flat-button color="primary" class="control-full-width mt-4"
            [disabled]="formData.invalid || loading" (click)="reset()"> RESET 
            <mat-icon *ngIf="loading">
                <mat-spinner diameter="20"></mat-spinner>
            </mat-icon>
        </button>
        

        <div *ngIf="error.action$ | async as err" class="alert alert-danger mt-3">
            {{ err }}
        </div>
    </ng-template>

    <div class="mt-5">
        <a mat-button color="primary" routerLink="/login">torna alla pagina di login</a>
    </div>

    <p class="py-4">
        <small class="text-muted text-center font-italic">ver. {{ appVersion }} </small>
    </p>
</div>
