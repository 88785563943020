import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class ContentTypeInterceptor implements HttpInterceptor {

    constructor() {}

    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

        req = req.clone({
            headers: req.headers.set('Accept', 'application/json'),
            withCredentials: true
        });

        if(!req.url.endsWith('/upload')) {
            req = req.clone({
                headers: req.headers.set('Content-Type', 'application/json')
            });
        }

        return next.handle(req);  
    }
}
