<div style="max-width: 480px;" class="p-5 mx-auto text-center">
    
    <img class="mt-5" src="assets/images/logo.png" alt="logo" width="200">
    
    <p class="mat-h2">
        RESET PASSWORD
    </p>
        
    <div *ngIf="(isMailSent.action$ | async) === true;then showInstructions else showForms"></div>
    
    <ng-template #showInstructions>
        <div class="alert alert-success mt-4">
            Le istruzioni per resettare la password sono state spedite all'indirizzo {{ formData.controls['email'].value }}
        </div>
    </ng-template>

    <ng-template #showForms>
        <form [formGroup]="formData" autocomplete="off" novalidate class="mt-4">
            <mat-form-field class="control-full-width" appearance="outline">
                <mat-label>Inserisci la tua email</mat-label>
                <input inputRefTrimValue matInput type="text" placeholder="pat@example.com" formControlName="email" required>
                <mat-icon matSuffix>email</mat-icon>
                <mat-error *ngIf="formData.controls['email'].errors?.['required']">Inserire email</mat-error>
                <mat-error *ngIf="formData.controls['email'].errors?.['email']">&apos;{{formData.controls['email'].value}}&apos; non &egrave; una email valida</mat-error>
                <mat-error *ngIf="formData.controls['email'].errors?.['maxLength']">Email troppo lunga</mat-error>
            </mat-form-field>
        </form>
    
        <button mat-flat-button color="primary" class="control-full-width mt-4"
            [disabled]="formData.invalid || loading" (click)="reset()"> RESET 
            <mat-icon *ngIf="loading">
                <mat-spinner diameter="20"></mat-spinner>
            </mat-icon>
        </button>
        
    
        <div *ngIf="error.action$ | async as err" class="alert alert-danger mt-3">
            {{ err }}
        </div>
    </ng-template>
    
    <div class="mt-5">
        <a mat-button color="primary" routerLink="/login">Torna alla pagina di login</a>
    </div>

    <p class="py-4">
        <small class="text-muted text-center font-italic">ver. {{ appVersion }} </small>
    </p>
</div>
