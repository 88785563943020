<h1 mat-dialog-title>
    {{ data.title }}
</h1>

<div mat-dialog-content>
    <p *ngFor="let msg of data.messages">
        {{ msg }}
    </p>
</div>

<div class="d-flex flex-row-reverse mt-3 mb-4 me-4">
    
    <button mat-flat-button color="primary" (click)="ok()">
        {{ data.label_ok ?? 'OK' }}
    </button>
    <button mat-stroked-button class="me-1" (click)="cancel()" *ngIf="showCancel">
        {{ data.label_cancel ?? 'Indietro' }}
    </button>
</div>