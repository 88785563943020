import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { DateTime } from 'luxon';

@Injectable()
export class TimestampsInterceptor implements HttpInterceptor {

    constructor() {}

    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

        return next.handle(req).pipe(
            map((val: HttpEvent<any>) => {
                if (val instanceof HttpResponse){
                    const body = val.body;
                    this.convert(body);
                }
                return val;
            }));

    }

    convert(body: any){
        if (body === null || body === undefined ) {
            return body;
        }
        if (typeof body !== 'object' ){
            return body;
        }
        for (const key of Object.keys(body))
        {
            if(key === 'created_at' || key === 'updated_at') {
                body[key] = DateTime.fromISO(body[key]);
            } else {
                this.convert(body[key]);
            }
        }
    }
}
