import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ActionBehaviorSubject } from '@app/common-models/action-behavior-subject';
import { LoggerService } from '@app/common-services/logger.service';
import { Observable, Subject, tap, catchError } from 'rxjs';
import { first, takeUntil, concatMap } from 'rxjs/operators';
import { test_users } from 'src/test_data';
import { AuthenticationService } from '../authentication.service';
import { environment } from 'src/environments/environment';
import { handleErrorDataRetrieving } from '@app/common-utilities/handleErrorDataRetrieving';
import { APP_VERSION } from '@app/current_version';
// import * as packageInfo from '../../../package.json';

@Component({
    templateUrl: './login.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent implements OnInit, OnDestroy {

    protected _destroy$ = new Subject<null>();
    formData: FormGroup;
    loading = false;
    error = new ActionBehaviorSubject<string>('');
    appVersion: string = APP_VERSION; // packageInfo.version;
    prodEnv = environment.production;
    users = test_users;

    constructor(private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private fb: FormBuilder,
        private logger: LoggerService
        //,private http: HttpClient
        ) { 
        
        // redirect to home if already logged in
        if (this.authenticationService.isLoggedIn()) { 
            this.router.navigateByUrl('/');
        }

        this.formData = this.fb.group({
            email: [ null, [Validators.required, Validators.email, Validators.maxLength(191)]],
            password: [ null, [Validators.required, Validators.maxLength(191)]],
        });
    }

    ngOnInit(): void {
    }

    ngOnDestroy() {
        this._destroy$.next(null);
        this._destroy$.complete();
    }

    setUser(id: number): void {
        const usr = this.users.find(x => x.id === id);
        if(usr) {
            this.formData.setValue({
                email: usr.email,
                password: usr.password
            });
        }
    }

    // convenience getter for easy access to form fields
    get f() { return this.formData.controls; }

    login() {
        if (this.formData.invalid) {
            return;
        }
        this.loading = true;
        this.error.set('');

        this.authenticationService.login(this.f['email'].value, this.f['password'].value)
        .pipe(takeUntil(this._destroy$))
            .subscribe({
                next: (twoFA: boolean) => {
                    this.loading = false;
                    const returnUrl = twoFA ? 'login2' : (this.authenticationService.redirectUrl || '/');
                    this.router.navigate([returnUrl]);
                },
                error: (err: string) => {
                    this.loading = false;
                    this.error.set('Utente o password non validi.');
                }
            });
    }

    
    
}


/*
test1() {
        return this.http.get(`${environment.apiUrl}test_get_home`)
        .pipe(
            catchError(err => handleErrorDataRetrieving(err, this.logger, 'test1', 'test1 error') ),
            tap( () => {
                this.logger.log('test1 completed.');                
            }),
            takeUntil(this._destroy$)
        ).subscribe({
            next: (o) => {
                this.logger.log('test1 - success');
            },
            error: err => {
                this.logger.log('test1 - error');
            }
        });
    }
    test2() {
        return this.http.get(`${environment.srvUrl}sanctum/csrf-cookie`)
        .pipe(
            catchError(err => handleErrorDataRetrieving(err, this.logger, 'test2', 'CSRF error') ),
            tap( () => {
                this.logger.log('test2 - csrf-cookie completed.');                
            }),
            takeUntil(this._destroy$)
        ).subscribe({
            next: (o) => {
                this.logger.log('test2 - success');
            },
            error: err => {
                this.logger.log('test2 - error');
            }
        });
    }

csrf() {
    this.authenticationService.csrf()
    .pipe(
        takeUntil(this._destroy$)
    )
    .subscribe({
        next: (o) => {
            this.logger.log('Dovrebbe essere finita la CSRF ...');
            const xx = this.cookieService.check('XSRF-TOKEN');
            this.logger.log(`Cookie XSRF-TOKEN: ${xx?'Y':'N'}`);
            this.error.next('');
        },
        error: err => {
            this.error.next(err.message);
            this.loading = false;
        }
    });
}

login() {
    // stop here if form is invalid
    if (this.formData.invalid) {
        return;
    }

    this.loading = true;

    this.authenticationService.login(this.f['email'].value, this.f['password'].value)
        .pipe(
            takeUntil(this._destroy$)
        )
        .subscribe({
            next: (o) => {
                this.error.next('');
                this.logger.log('Dovrebbe essere finita la LOGIN ...');
                
                // get return url from route parameters or default to '/'
                const returnUrl = this.authenticationService.redirectUrl || '/';
                this.loading = false;
                this.router.navigate([returnUrl]);
            },
            error: err => {
                this.error.next(err.message);
                this.loading = false;
            }
        });
}
*/