import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ActionBehaviorSubject } from '@app/common-models/action-behavior-subject';
import { LoggerService } from '@app/common-services/logger.service';
import { APP_VERSION } from '@app/current_version';
import { Subject, takeUntil } from 'rxjs';
import { AuthenticationService } from '../authentication.service';

@Component({
  templateUrl: './forgot-pwd.component.html',
  styles: [
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ForgotPwdComponent implements OnInit, OnDestroy {

    protected _destroy$ = new Subject<null>();
    appVersion: string = APP_VERSION;
    formData: FormGroup;
    loading = false;
    error = new ActionBehaviorSubject<string>('');
    isMailSent = new ActionBehaviorSubject<boolean>(false);
    emailSent: boolean = false;

    constructor(private router: Router,
        private authenticationService: AuthenticationService,
        private fb: FormBuilder,
        private logger: LoggerService) { 
        
        // redirect to home if already logged in
        if (this.authenticationService.isLoggedIn()) { 
            this.router.navigateByUrl('/');
        }

        this.formData = this.fb.group({
            email: [ null, [Validators.required, Validators.email, Validators.maxLength(191)]],            
        });
    }
    
    ngOnInit(): void {
    }

    ngOnDestroy() {
        this._destroy$.next(null);
        this._destroy$.complete();
    }

    reset() {
        if (this.formData.invalid) {
            return;
        }
        this.loading = true;
        this.error.set('');

        this.authenticationService.forgotPassword(this.formData.controls['email'].value)
        .pipe(takeUntil(this._destroy$))
            .subscribe({
                next: () => {
                    this.loading = false;
                    this.isMailSent.set(true);
                },
                error: (err: string) => {
                    this.loading = false;                    
                    this.error.set('Errore');
                }
            });

    }

}
