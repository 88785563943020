import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
    
export function controlsMatcher(controlName: string, confirmControlName: string): ValidatorFn {
    return (c: AbstractControl): ValidationErrors | null => {
        let control = c.get(controlName);
        let confirmControl = c.get(confirmControlName);
        if( control?.pristine || confirmControl?.pristine ) {
            return null;
        }
        if( control?.value === confirmControl?.value ) {
            return null;
        }
        return { 'match': true };
    }
}