import { HttpErrorResponse } from "@angular/common/http";
import { LoggerService } from "@app/common-services/logger.service";
import { throwError } from "rxjs";

export function handleErrorDataRetrieving(
    error: any,
    logger: LoggerService,
    serviceName = 'serviceName',
    serviceMsg: string)
{    
    logger.error(`${serviceName} - ${serviceMsg}`);

    if (error instanceof HttpErrorResponse )
    {
        if (error.error?.message) {
            logger.error(`${serviceName} - ${error.error.message} (${error.status} ${error.statusText})`);
        }
        if (error.error?.errors) {
            const keys = Object.keys(error.error.errors);
            for(const key of keys) {
                const keyErrors = error.error.errors[key];
                for(const keyError of keyErrors) {
                    logger.error(`${serviceName} - ${key}: ${keyError}`);
                }
            }
        }
    }

    return throwError(() => error);
}