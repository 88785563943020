import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, concatMap, of, forkJoin } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { EMPTY_USER } from '@app/common-models/users';
import { CookieService } from 'ngx-cookie-service';
import { LoggerService } from '../common-services/logger.service';
import { handleErrorDataRetrieving } from '@app/common-utilities/handleErrorDataRetrieving';
import { StoreService } from '../common-services/store.service';
import { IQRCodeResponse, IRegistration, IResetPassword, IUpdatePassword, IVerificationCodesResponse } from './auth-model';
import { IUserInfo } from '@app/common-models/user_info.model';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {

    private readonly LOGGED_USER_COOKIE_NAME = 'is_user_logged_in';
    redirectUrl: string | undefined = undefined;

    constructor(private http: HttpClient, private cookieService: CookieService, 
        private logger: LoggerService, private storeService: StoreService) {
    }

    login(email: string, password: string) {
        const dbgMethodname = 'AuthenticationService::login()';
        
        this.logger.log(dbgMethodname + ' - csrf-cookie ...');    
        return this.http.get(`${environment.srvUrl}sanctum/csrf-cookie`)
        .pipe(
            catchError(err => handleErrorDataRetrieving(err, this.logger, dbgMethodname, 'CSRF error') ),
            tap( () => {
                this.logger.log(dbgMethodname + ' - csrf-cookie completed.');                
            }),
            concatMap( () => {
                this.logger.log(dbgMethodname + ` - login ...`);
                return this.http.post<any>(`${environment.apiUrl}login`, {
                        email: email.trim(),
                        password
                    })
                    .pipe(
                        catchError(err => handleErrorDataRetrieving(err, this.logger, dbgMethodname, 'Login error') ),
                        tap( () => {
                            this.logger.log(dbgMethodname + ' - login completed.');
                            this.cookieService.set(
                                this.LOGGED_USER_COOKIE_NAME, 'true', 
                                { expires: 86400, sameSite: 'Lax' });
                        }),
                        map( (res) => !!res.two_factor )
                    );
            })
        );  
    }
    
    logout(): Observable<any> {
        return this.http.post( `${environment.apiUrl}logout`, {})
            .pipe(
                tap( res => {
                    this.clearLoggedUser();
                }),
                catchError( err => handleErrorDataRetrieving(err, this.logger, 
                    'AuthenticationService::logout()', 'Error logging out') )
            );
    }

    isLoggedIn(): boolean {
        return this.cookieService.check(this.LOGGED_USER_COOKIE_NAME);
    }
    
    isPrivacyOrRegulationMissing(): boolean {
        const client_info = this.storeService.loggedUserClientInfo.get();
        return client_info ? (/*(!!client_info.privacy) &&*/ (!!client_info.regulation)) : false;
    }

    clearLoggedUser(): void {
        this.storeService.loggedUser.set(EMPTY_USER);
        this.cookieService.delete(this.LOGGED_USER_COOKIE_NAME);
        this.storeService.loggedUserClientInfo.set(null);
    }

    private isLoadingLoggedUser = new BehaviorSubject<boolean>(false);
    isLoadingLoggedUser$ = this.isLoadingLoggedUser.asObservable();
    getLoggedUser(): Observable<IUserInfo> {
        this.isLoadingLoggedUser.next(true);
        const dbgMethodname = `AuthenticationService.getLoggedUser()`;
        const path = `${environment.apiUrl}me`;
        this.logger.log(`${dbgMethodname} ...`);

        return this.http.get<IUserInfo>(path)
            .pipe(
                catchError( err => {
                    this.isLoadingLoggedUser.next(false);
                    return handleErrorDataRetrieving(err, this.logger, 'AuthenticationService', 'Error retrieving logged user');
                }),
                tap( userInfo => {
                    this.logger.log(`${dbgMethodname} - completed`);
                    if(userInfo) {
                        this.storeService.loggedUser.set(userInfo.user);
                        this.storeService.loggedUserPermission.set(userInfo.permissions);
                        this.storeService.loggedUserClientInfo.set(userInfo.client_info);
                    }
                    this.isLoadingLoggedUser.next(false);
                })
            );
    }

    register(data: IRegistration) {
        const dbgMethodname = `AuthenticationService.register()`;

        this.logger.log(dbgMethodname + ' - csrf-cookie ...');    
        return this.http.get(`${environment.srvUrl}sanctum/csrf-cookie`)
        .pipe(
            catchError(err => handleErrorDataRetrieving(err, this.logger, dbgMethodname, 'CSRF error') ),
            tap( () => {
                this.logger.log(dbgMethodname + ' - csrf-cookie completed.');                
            }),
            concatMap( () => {
                this.logger.log(dbgMethodname + ` - register ...`);
                return this.http.post<any>(`${environment.apiUrl}register`, data)
                    .pipe(
                        catchError(err => handleErrorDataRetrieving(err, this.logger, dbgMethodname, 'Register error') ),
                        tap( () => {
                            this.logger.log(dbgMethodname + ' - register completed.');
                            // la register fa anche il login ! (Laravel\Fortify\Http\Controllers\RegisteredUserController@store)
                            this.cookieService.set(
                                 this.LOGGED_USER_COOKIE_NAME, 'true', 
                                 { expires: 86400, sameSite: 'Lax' });
                        }),
                        map( (res) => !!res.two_factor )
                    );
            })
        );
    }

    verifyCode(code: string, isVerificationCode: boolean) {
        const dbgMethodname = `AuthenticationService.verifyCode()`;
        const path = `${environment.apiUrl}two-factor-challenge`;
        this.logger.log(`${dbgMethodname} ...`);
        const data = isVerificationCode ? {recovery_code: code} : {code: code};
        return this.http.post<any>(path, data)
            .pipe(
                catchError( (err) => handleErrorDataRetrieving(err, this.logger, 
                        'AuthenticationService', 'Verifica fallita.')
                ),
                tap( () => this.logger.log(`${dbgMethodname} - completed`) )
            );
    }

    getConfirmPasswordStatus() {
        const dbgMethodname = `AuthenticationService.getConfirmPasswordStatus()`;
        const path = `${environment.apiUrl}user/confirmed-password-status`;
        this.logger.log(`${dbgMethodname} ...`);

        return this.http.get<any>(path)
            .pipe(
                catchError( (err) => handleErrorDataRetrieving(err, this.logger, 
                    'AuthenticationService', 'Errore stato password.')
                ),
                tap( () => this.logger.log(`${dbgMethodname} - completed`)),
                map( (pwdStatus) => pwdStatus.confirmed)
            );
    }

    enable2FA() {
        const dbgMethodname = `AuthenticationService.enable2FA()`;
        const path = `${environment.apiUrl}user/two-factor-authentication`;
        this.logger.log(`${dbgMethodname} ...`);

        return this.http.post<any>(path, {})
            .pipe(
                catchError( (err) => {
                    if(err.status === 423) {
                        this.logger.error(`${dbgMethodname} - need password confirmation`);
                    }
                    return handleErrorDataRetrieving(err, this.logger, 
                        'AuthenticationService', 'Errore nell\'abilitare l\'autenticazione a 2 fattori.');
                }),
                tap( () => this.logger.log(`${dbgMethodname} - completed`) )
            );
    }

    disable2FA() {
        const dbgMethodname = `AuthenticationService.disable2FA()`;
        const path = `${environment.apiUrl}user/two-factor-authentication`;
        this.logger.log(`${dbgMethodname} ...`);

        return this.http.delete<any>(path)
            .pipe(
                catchError( (err) => handleErrorDataRetrieving(err, this.logger, 
                        'AuthenticationService', 'Errore nell\'abilitare l\'autenticazione a 2 fattori.')
                ),
                tap( () => this.logger.log(`${dbgMethodname} - completed`) )
            );
    }

    confirmPassword(password: string) {
        const dbgMethodname = `AuthenticationService.confirmPassword()`;
        const path = `${environment.apiUrl}user/confirm-password`;
        this.logger.log(`${dbgMethodname} ...`);

        return this.http.post<any>(path, {password})
            .pipe(
                catchError( (err) => handleErrorDataRetrieving(err, this.logger, 
                        'AuthenticationService', 'Password errata.')),
                tap( user => {
                    this.logger.log(`${dbgMethodname} - completed`);
                })
            );
    }

    getVerificationCodes(): Observable<IVerificationCodesResponse> {
        const dbgMethodname = `AuthenticationService.getVerificationCodes()`;
        this.logger.log(`${dbgMethodname} ...`);

        return forkJoin([
            this.http.get<IQRCodeResponse>(`${environment.apiUrl}user/two-factor-qr-code`),
            this.http.get<string[]>(`${environment.apiUrl}user/two-factor-recovery-codes`)
        ]).pipe(
            catchError(err => handleErrorDataRetrieving(err, this.logger, dbgMethodname, 'Verification codes error') ),
            tap( () => this.logger.log(dbgMethodname + ' - completed.')),
            map( ([qrCode, verificationCodes]) => ({ 
                qrCode: qrCode.svg, 
                verificationCodes 
            }))
        );
    }

    updateProfileInfo(name: string, surname: string, email: string) {
        const dbgMethodname = `AuthenticationService.updateProfileInfo()`;
        const path = `${environment.apiUrl}user/profile-information`;
        this.logger.log(`${dbgMethodname} ...`);

        return this.http.put<any>(path, { name, surname, email })
            .pipe(
                catchError( (err) => handleErrorDataRetrieving(err, this.logger, 
                    'AuthenticationService', 'Errore nell\'aggiornamento utente.')
                ),
                tap( () => this.logger.log(`${dbgMethodname} - completed`) )
            );
    }

    updatePassword(pwd: IUpdatePassword) {
        const dbgMethodname = `AuthenticationService.updatePassword()`;
        const path = `${environment.apiUrl}user/password`;
        this.logger.log(`${dbgMethodname} ...`);

        return this.http.put<any>(path, pwd)
            .pipe(
                catchError( (err) => handleErrorDataRetrieving(err, this.logger, 
                    'AuthenticationService', 'Errore nell\'aggiornamento della password.')
                ),
                tap( () => this.logger.log(`${dbgMethodname} - completed`) )
            );
    }

    forgotPassword(email: string) {
        const dbgMethodname = `AuthenticationService.forgotPassword()`;
        const path = `${environment.apiUrl}forgot-password`;        
        
        this.logger.log(dbgMethodname + ' - csrf-cookie ...');    
        return this.http.get(`${environment.srvUrl}sanctum/csrf-cookie`)
        .pipe(
            catchError(err => handleErrorDataRetrieving(err, this.logger, dbgMethodname, 'CSRF error') ),
            tap( () => {
                this.logger.log(dbgMethodname + ' - csrf-cookie completed.');                
            }),
            concatMap( () => {
                this.logger.log(`${dbgMethodname} ...`);
                return this.http.post<any>(path, {email})
                    .pipe(
                        catchError(err => handleErrorDataRetrieving(err, this.logger, dbgMethodname, 'Forgot password error') ),
                        tap( () => this.logger.log(`${dbgMethodname} - completed`))
                    );
            })
        );  
    }


    resetPassword(data: IResetPassword) {
        const dbgMethodname = `AuthenticationService.resetPassword()`;
        const path = `${environment.apiUrl}reset-password`;        
        
        this.logger.log(dbgMethodname + ' - csrf-cookie ...');    
        return this.http.get(`${environment.srvUrl}sanctum/csrf-cookie`)
            .pipe(
                catchError(err => handleErrorDataRetrieving(err, this.logger, dbgMethodname, 'CSRF error') ),
                tap( () => {
                    this.logger.log(dbgMethodname + ' - csrf-cookie completed.');                
                }),
                concatMap( () => {
                    this.logger.log(`${dbgMethodname} ...`);
                    return this.http.post<any>(path, data)
                        .pipe(
                            catchError(err => handleErrorDataRetrieving(err, this.logger, dbgMethodname, 'Reset password error') ),
                            tap( () => this.logger.log(`${dbgMethodname} - completed`))
                        );
                })
            );  
    }
}

 /*
    const user: IUser = { 
        id: res['user'].id,
        name: res['user'].name,
        mail: res['user'].email,
        access_token: res['access_token'],
        expires_in: res['expires_in']
    };
    this.currentUserSubject.next(user);
*/


/*

    getQRcode() {
        const dbgMethodname = `AuthenticationService.getQRcode()`;
        const path = `${environment.apiUrl}user/two-factor-qr-code`;
        this.logger.log(`${dbgMethodname} ...`);

        return this.http.get<any>(path)
            .pipe(
                catchError( (err) => handleErrorDataRetrieving(err, this.logger, 
                    'AuthenticationService', 'QR code error.')),
                tap(() => this.logger.log(`${dbgMethodname} - completed`)),
                map((qr) => qr.svg )
            );
    }
    getRecoveryCode() {
        const dbgMethodname = `AuthenticationService.getRecoveryCode()`;
        const path = `${environment.apiUrl}user/two-factor-recovery-codes`;
        this.logger.log(`${dbgMethodname} ...`);

        return this.http.get<any>(path)
            .pipe(
                catchError( (err) => handleErrorDataRetrieving(err, this.logger, 
                    'AuthenticationService', 'Recovery code error.')),
                tap(() => this.logger.log(`${dbgMethodname} - completed`))
            );
    }
    csrf() {
        return this.http.get(`${environment.srvUrl}sanctum/csrf-cookie`)
        .pipe(
            catchError(error => {
                console.error(error.message);
                return throwError(() => error);
            }),
            tap( (res) => {
                this.logger.log('AuthenticationService::login() - GET csrf-cookie');                
            })
        );
    }

    login(email: string, password: string) {

        return this.http.post<any>(`${environment.srvUrl}login`, {email, password})
            .pipe(
                catchError(error => {
                    console.error(error.message);
                    return throwError(() => error);
                }),
                tap( res => {
                    this.logger.log('AuthenticationService::login() - POST login')
                    this.cookieService.set('is_user_logged_in', 'true', { expires: 86400, sameSite: 'Lax' });
                })
            );

    }
*/
    /*
    https://stackoverflow.com/questions/61063479/csrf-token-mismatch-laravel-sanctum-and-angular-http
    https://stackoverflow.com/questions/50510998/angular-6-does-not-add-x-xsrf-token-header-to-http-request
    https://www.youtube.com/channel/UCNoeG15U4pLBrFVHTw3xByw
    */
   