import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './auth/login/login.component';
import { SharedModule } from './shared.module';
import { CookieService } from 'ngx-cookie-service';
import { TwoFAComponent } from './auth/login/two-fa.component';
import { ConfirmDialogComponent } from './common-utilities/confirm-dialog.component';
import { ForgotPwdComponent } from './auth/login/forgot-pwd.component';
import { ResetPwdComponent } from './auth/login/reset-pwd.component';
import { DateTime } from 'luxon';
import { RegisterComponent } from './auth/login/register.component';
import { RecaptchaSettings, RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from "ng-recaptcha";
import { environment } from '@environments/environment';
import {NgcCookieConsentConfig, NgcCookieConsentModule} from 'ngx-cookieconsent';
import { HowToPayDialogComponent } from './common-utilities/how-to-pay-dialog.component';
import { InputRefTrimValueDirective } from './common-utilities/trim-value-accessor.directive';

const cookieConfig:NgcCookieConsentConfig = {
    "cookie": {
      "domain": "appsmilevil.it"
    },
    "position": "bottom",
    "theme": "classic",
    "palette": {
      "popup": {
        "background": "#000000",
        "text": "#ffffff",
        "link": "#ffffff"
      },
      "button": {
        "background": "#f1d600",
        "text": "#000000",
        "border": "transparent"
      }
    },
    "type": "info",
    "content": {
      "message": "Questo sito usa solo cookies necessari a rendere fruibile il sito web abilitandone funzionalità di base quali la navigazione sulle pagine e l'accesso alle aree protette del sito. Il sito web non è in grado di funzionare correttamente senza questi cookie.",
      "dismiss": "OK",
      "deny": "Decline",
      "link": "Learn more",
      "href": "https://cookiesandyou.com",
      "policy": "Cookie Policy",
      "header": "Cookies used on the website!",
      "allow": "Allow cookies"
    }
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    TwoFAComponent,
    ConfirmDialogComponent,
    HowToPayDialogComponent,
    ForgotPwdComponent,
    ResetPwdComponent,
    RegisterComponent,
    InputRefTrimValueDirective,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    RecaptchaV3Module,
    NgcCookieConsentModule.forRoot(cookieConfig),
  ],
  providers: [
    CookieService,
    ConfirmDialogComponent,
    HowToPayDialogComponent,
    { 
        provide: RECAPTCHA_V3_SITE_KEY, 
        useValue: environment.recaptcha.siteKey,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
    constructor() { 
        console.log(`[${DateTime.now().toFormat('HH:mm:ss.SSS')}] Loading module: "AppModule" (${DateTime.local().zoneName})`);        
    }
 }

 
