import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ActionBehaviorSubject } from '@app/common-models/action-behavior-subject';
import { LoggerService } from '@app/common-services/logger.service';
import { RecaptchaErrorParameters, ReCaptchaV3Service } from 'ng-recaptcha';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IRegistration } from '../auth-model';
import { AuthenticationService } from '../authentication.service';
import { controlsMatcher } from '@app/common-utilities/controls-matcher';
import { InvalidParentErrorMatcher } from '@app/common-utilities/invalid-parent-error-matcher';
import { environment } from '@environments/environment';
import { APP_VERSION } from '@app/current_version';
import { EMPTY_PASSWORD_CONSTRAINTS, evalPasswordContraints, IPasswordContraints, pwdValidator } from '@app/common-utilities/password-utilities';

@Component({
    templateUrl: './register.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegisterComponent implements OnInit, OnDestroy {

    protected _destroy$ = new Subject<null>();
    appVersion: string = APP_VERSION;
    formData: FormGroup;
    loading = false;
    error = new ActionBehaviorSubject<string>('');
    invalidParentErrorMatcher = new InvalidParentErrorMatcher();    

    private _registrationSent = new BehaviorSubject<boolean>(false);
    registrationSent$ = this._registrationSent.asObservable();

    _pwd_status = new BehaviorSubject<IPasswordContraints>(EMPTY_PASSWORD_CONSTRAINTS);
    pwd_status$ = this._pwd_status.asObservable();    

    constructor(private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private fb: FormBuilder,
        private recaptchaV3Service: ReCaptchaV3Service,
        private logger: LoggerService) { 
        
        // redirect to home if already logged in
    
        if (this.authenticationService.isLoggedIn()) { 
            this.router.navigateByUrl('/');
        }
    

        this.formData = this.fb.group({
            name:                   [ null, [Validators.required, Validators.maxLength(191)]],
            surname:                [ null, [Validators.required, Validators.maxLength(191)]],
            email:                  [ null, [Validators.required, Validators.email, Validators.maxLength(191)]],
            passwordGroup: this.fb.group({
                password: [ null, [Validators.required, pwdValidator]],
                password_confirmation: [ null, [Validators.required]]
            }, {
                validators: controlsMatcher('password', 'password_confirmation')
            })
        });
    }

    ngOnInit(): void {
        (<FormGroup>this.formData.controls['passwordGroup']).controls['password'].valueChanges.subscribe((pwd: string) => {
            this._pwd_status.next(evalPasswordContraints(pwd));
        });
    }

    ngOnDestroy() {
        this._destroy$.next(null);
        this._destroy$.complete();
    }

    // convenience getter for easy access to form fields
    get f() { return this.formData.controls; }
    get passwordGroup(): FormGroup {
        return <FormGroup>this.formData.controls['passwordGroup'];
    }

    public try_register(): void {
        if(environment.production) {
            this.recaptchaV3Service.execute('register')
                .pipe(takeUntil(this._destroy$))
                .subscribe((token: string) => this.register(token));
        } else {
            this.register('');
        }
        
    }
    
    register(grecaptcha_v3_token: string) {
        if (this.formData.invalid) {
            return;
        }
        this.loading = true;
        this.error.set('');

        const data: IRegistration = {
            name:                   this.f['name'].value,
            surname:                this.f['surname'].value,
            email:                  this.f['email'].value,
            password:               (<FormGroup>this.f['passwordGroup']).controls['password'].value,
            password_confirmation:  (<FormGroup>this.f['passwordGroup']).controls['password_confirmation'].value,
            user_type:              'client',
            grecaptcha_v3_token
        };

        this.authenticationService.register(data)
        .pipe(takeUntil(this._destroy$))
            .subscribe({
                next: (twoFA: boolean) => {
                    this.loading = false;
                    this._registrationSent.next(true);
                    const returnUrl = twoFA ? 'login2' :  '/';
                    this.router.navigateByUrl(returnUrl);
                },
                error: (err: string) => {
                    this.loading = false;
                    this.error.set('Errore durante la registrazione.');
                }
            });
    }
    
}

