<div style="max-width: 480px;" class="p-5 mx-auto text-center">
    
    <img class="mt-5" src="assets/images/logo.png" alt="logo" width="200">
    
    <form [formGroup]="formData" autocomplete="off" novalidate class="mt-4">
        <mat-form-field class="control-full-width" appearance="outline">
            <mat-label>Enter {{ useRecoveryCode() ? 'recovery code' : 'code' }}</mat-label>
            <input matInput type="text" formControlName="code" required>
            <mat-icon matSuffix>{{ useRecoveryCode() ? 'vpn_key' : 'qr_code_2' }}</mat-icon>
            <mat-error *ngIf="formData.controls['code']">Inserire {{ useRecoveryCode() ? 'recovery code' : 'codide QR' }}</mat-error>
        </mat-form-field>
        <mat-checkbox formControlName="useRecoveryCode">Usa Recovery Code</mat-checkbox>
    </form>

    <button mat-flat-button color="primary" class="control-full-width mt-4"
        [disabled]="formData.invalid || loading" (click)="login()"> VERIFICA 
        <mat-icon *ngIf="loading">
            <mat-spinner diameter="20"></mat-spinner>
        </mat-icon>
    </button>
        
    <p class="py-4">
        <small class="text-muted text-center font-italic">ver. {{ appVersion }} </small>
    </p>
</div>
