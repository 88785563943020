import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ActionBehaviorSubject } from '@app/common-models/action-behavior-subject';
import { LoggerService } from '@app/common-services/logger.service';
import { controlsMatcher } from '@app/common-utilities/controls-matcher';
import { InvalidParentErrorMatcher } from '@app/common-utilities/invalid-parent-error-matcher';
import { EMPTY_PASSWORD_CONSTRAINTS, evalPasswordContraints, IPasswordContraints } from '@app/common-utilities/password-utilities';
import { APP_VERSION } from '@app/current_version';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { IResetPassword } from '../auth-model';
import { AuthenticationService } from '../authentication.service';

@Component({
  templateUrl: './reset-pwd.component.html',
  styles: [
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResetPwdComponent implements OnInit {
    
    protected _destroy$ = new Subject<null>();
    appVersion: string = APP_VERSION;
    formData: FormGroup;
    loading = false;
    error = new ActionBehaviorSubject<string>('');
    isPasswordChanged = new ActionBehaviorSubject<boolean>(false);
    invalidParentErrorMatcher = new InvalidParentErrorMatcher();    
    token: string|null = null;

    _pwd_status = new BehaviorSubject<IPasswordContraints>(EMPTY_PASSWORD_CONSTRAINTS);
    pwd_status$ = this._pwd_status.asObservable();

    constructor(private router: Router,
        private route: ActivatedRoute,
        private authenticationService: AuthenticationService,
        private fb: FormBuilder,
        private logger: LoggerService) { 
        
        // redirect to home if already logged in
        if (this.authenticationService.isLoggedIn()) { 
            this.router.navigateByUrl('/');
        }

        this.formData = this.fb.group({
            email: [ {value: null, disabled: true}, [Validators.required, Validators.email, Validators.maxLength(191)]],
            passwordGroup: this.fb.group({
                password: [ null, [Validators.required, Validators.minLength(8), Validators.maxLength(64)]],
                password_confirmation: [ null, [Validators.required]]
            }, {
                validators: controlsMatcher('password', 'password_confirmation')
            })
        });
    }
    
    get passwordGroup(): FormGroup {
        return <FormGroup>this.formData.controls['passwordGroup'];
    }

    ngOnInit(): void {
        this.route.params.subscribe({
            next: (params) => this.token = params['token']
        });
        this.route.queryParams.subscribe({
            next: (params) => this.formData.controls['email'].setValue(params['email'])
        });
        (<FormGroup>this.formData.controls['passwordGroup']).controls['password'].valueChanges.subscribe((pwd: string) => {
            this._pwd_status.next(evalPasswordContraints(pwd));
        });
    }

    ngOnDestroy() {
        this._destroy$.next(null);
        this._destroy$.complete();
    }

    reset() {
        if (this.formData.invalid || (!this.token)) {
            return;
        }
        this.loading = true;
        this.error.set('');

        const data: IResetPassword = {
            email: this.formData.controls['email'].value,
            password: this.passwordGroup.controls['password'].value,
            password_confirmation: this.passwordGroup.controls['password_confirmation'].value,
            token: this.token
        }
        this.authenticationService.resetPassword(data)
            .pipe(takeUntil(this._destroy$))
                .subscribe({
                    next: () => {
                        this.loading = false;
                        this.isPasswordChanged.set(true);
                    },
                    error: (err: string) => {
                        this.loading = false;                  
                        this.error.set('Errore durante il reset della password.');
                    }
                });

    }

}
