import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route, UrlTree, ActivatedRoute, UrlSegment, CanActivate} from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { LoggerService } from '../common-services/logger.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard {

    constructor(
        private authService: AuthenticationService, 
        private router: Router, 
        private logger: LoggerService) {}
/*
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean | UrlTree
    {
        if (this.authService.isLoggedIn()) {
            return true;
        }
        
        // l'utente non è loggato, si salva l'URL di destinazione e si indirizza alla pagina di login
        this.authService.redirectUrl = state.url;
        const queryParams = next.queryParams;
        return this.router.createUrlTree(['login']);
    }
    

    canLoad(route: Route): boolean {
        this.logger.log('In canLoad: ' + route.path);
        return this.checkLoggedIn(route.path);
    }

    checkLoggedIn(url: string | undefined): boolean {
        if (this.authService.isLoggedIn()) {
            return true;
        }

        this.logger.warning('Unknown user. Redirect to login page ... ');

        // Retain the attempted URL for redirection
        if(url) {            
            this.authService.redirectUrl = url;
        }
        this.router.navigateByUrl('/login');
        return false;
    }

    canLoad2(route: Route, segments: UrlSegment[]): boolean | UrlTree {
        if (!this.authService.isLoggedIn()) {
          // l'utente non è loggato, si salva l'URL di destinazione e si indirizza alla pagina di login
          const urlTree = this.router.createUrlTree(['/login'], {
            queryParams: { returnUrl: this.router.url }
          });
          return urlTree;
        }
        // l'utente è loggato, permetti il caricamento del modulo richiesto
        return true;
    }
    */
    
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
        // controlla se l'utente è autenticato
        const isAuthenticated = this.authService.isLoggedIn();
    
        if (isAuthenticated) {
            return true;
        } else {
            // salva l'URL originale
            this.authService.redirectUrl = state.url;
            return this.router.createUrlTree(['login']);
        }
    }

    isLoggedIn(route: Route, segments: UrlSegment[]): boolean | UrlTree {
        if (!this.authService.isLoggedIn()) 
        {
            this.logger.warning('AuthGuard::isLoggedIn() - Unknown user. Redirect to login page ... ');
            
            // Retain the attempted URL for redirection
            this.authService.redirectUrl = segments.join('/');
            //this.logger.log(`AuthGuard::isLoggedIn() - save redirectUrl: ${this.authService.redirectUrl}`);
            return this.router.createUrlTree(['login']);
        }
        // else { this.logger.log('AuthGuard::isLoggedIn() - user logged !');}
        return true;
    }
    
}
