import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpXsrfTokenExtractor,
  HttpResponse,
  HttpErrorResponse,
  HttpEventType
} from '@angular/common/http';
import { finalize, Observable, tap } from 'rxjs';
import { LoggerService } from '@app/common-services/logger.service';

@Injectable()
export class XsrfInterceptor implements HttpInterceptor {


    constructor(private tokenExtractor: HttpXsrfTokenExtractor, private logger: LoggerService) {}

    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {        
        
        // make sure that the Allow-Origin-With-Credentials header is set on the server.
        // and the server has included the CORS header Access-Control-Allow-Credentials: true in the pre-flight OPTIONS
        
        if (req.method === 'GET' || req.method === 'HEAD') {
            return next.handle(req);
        }

        let csrfToken = this.tokenExtractor.getToken() as string;
        if (csrfToken !== null && !req.headers.has('X-XSRF-TOKEN')) {
            req = req.clone({ headers: req.headers.set('X-XSRF-TOKEN', csrfToken) });
        }
        
        return next.handle(req);        
    }
}
