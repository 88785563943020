import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


export interface ConfirmDialogComponentData {
    title: string;
    messages?: string[];    
    showCancel?: boolean;

    label_ok?: string;
    label_cancel?: string;
}

@Component({
    templateUrl: './confirm-dialog.component.html',
})
export class ConfirmDialogComponent {

    showCancel: boolean = true;

    constructor(
        public dialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogComponentData) {     
            
        if(data.showCancel){
            this.showCancel = data.showCancel;
        }
    }

    ok(): void {        
        this.dialogRef.close(true);
    }
    cancel(): void {
        this.dialogRef.close(false);
    }

}
