import { Component } from '@angular/core';

@Component({
  template: `
    <h1 class="errorMessage">404 - This is not the page you were looking for!</h1>
  `,
  styles: [`
    .errorMessage {
      margin-top:150px;
      font-size: 16px;
      text-align: center;
    }`]
})
export class Error404Component {
  constructor() {

  }
}
