import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ActionBehaviorSubject } from '@app/common-models/action-behavior-subject';
import { AuthenticationService } from '@app/auth/authentication.service';
import { LoggerService } from '@app/common-services/logger.service';
import { Subject, takeUntil } from 'rxjs';
import { APP_VERSION } from '@app/current_version';

@Component({
    selector: 'app-two-fa',
    templateUrl: './two-fa.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TwoFAComponent implements OnInit {

    protected _destroy$ = new Subject<null>();
    formData: FormGroup;
    loading = false;
    appVersion: string = APP_VERSION; // packageInfo.version;

    constructor(private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private fb: FormBuilder,
        private logger: LoggerService) { 
        this.formData = this.fb.group({
            code: [ null, [Validators.required]],
            useRecoveryCode: [false]
        });
    }

    ngOnInit(): void {
    }

    useRecoveryCode(): boolean {
        return !!this.formData.controls['useRecoveryCode'].value;
    }

    login() {
        this.loading = true;
        const code = this.formData.controls['code'].value;
        const useVerificationCode = !!this.formData.controls['useRecoveryCode'].value;
        this.authenticationService.verifyCode(code, useVerificationCode)
        .pipe(takeUntil(this._destroy$))
            .subscribe({
                next: () => {
                    this.loading = false;
                    const returnUrl = this.authenticationService.redirectUrl || '/';
                    this.router.navigate([returnUrl]);
                },
                error: () => {
                    this.loading = false;
                    this.router.navigateByUrl('login');
                }
            });
    }

}
