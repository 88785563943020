<div style="max-width: 480px;" class="px-5 mx-auto text-center">
    
    <img class="mt-1" src="assets/images/logo.png" alt="logo" width="200">
    
    <p class="mat-h2">
        ACCEDI o <a class="p-0" mat-button color="primary" routerLink="/register">REGISTRATI</a>  
    </p>

    <form [formGroup]="formData" autocomplete="off" novalidate class="mt-4">
        <mat-form-field class="control-full-width" appearance="outline">
            <mat-label>Inserisci la tua email</mat-label>
            <input inputRefTrimValue matInput type="text" placeholder="nome@esempio.com" formControlName="email" required autocomplete="username">
            <mat-icon matSuffix>email</mat-icon>
            <mat-error *ngIf="f['email'].errors?.['required']">Inserire email</mat-error>
            <mat-error *ngIf="f['email'].errors?.['email']">&apos;{{f['email'].value}}&apos; non &egrave; una email valida</mat-error>
            <mat-error *ngIf="f['email'].errors?.['maxLength']">Email troppo lunga</mat-error>
        </mat-form-field>            
        <mat-form-field class="mt-2 control-full-width" appearance="outline">
            <mat-label>Password</mat-label>
            <input inputRefTrimValue matInput type="password" formControlName="password" required autocomplete="current-password">
            <mat-icon matSuffix>vpn_key</mat-icon>
            <mat-error *ngIf="f['password'].errors?.['required']">Inserire password</mat-error>
        </mat-form-field>
    </form>

    <button mat-flat-button color="primary" class="control-full-width mt-3"
        [disabled]="formData.invalid || loading" (click)="login()"> LOGIN 
        <mat-icon *ngIf="loading">
            <mat-spinner diameter="20"></mat-spinner>
        </mat-icon>
    </button>
    
    <mat-divider></mat-divider>
    <div class="d-flex flex-wrap" *ngIf="!prodEnv">
        <button mat-raised-button *ngFor="let u of users" (click)="setUser(u.id)">{{ u.name }}</button>
    </div>
    <mat-divider></mat-divider>

    <div *ngIf="error.action$ | async as err" class="alert alert-danger mt-3">
        {{ err }}
    </div>

    <div class="mt-5">
        <a mat-button color="primary" routerLink="/forgot-password">Password dimenticata ?</a>
    </div>
            
    <p class="py-4">
        <small class="text-muted text-center font-italic">ver. {{ appVersion }} </small>
    </p>
</div>
