import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActionBehaviorSubject } from '@app/common-models/action-behavior-subject';
import { APP_PERMISSION_KEY, IDisplay } from '@app/common-models/common.model';
import { EMPTY_USER, IUser } from '@app/common-models/users';
import { BehaviorSubject } from 'rxjs';
import { LoggerService } from './logger.service';
import { IUserInfo } from '@app/common-models/user_info.model';
import { IClientInfo } from '@app/common-models/client.model';

@Injectable({
    providedIn: 'root'
})
export class StoreService {

    map = new Map<string, any>();

    constructor(private logger: LoggerService, private http: HttpClient) { }

    // DESKTOP or MOBILE ?
    display = new ActionBehaviorSubject<IDisplay>({isDesktop: undefined});

    // YEAR
    yearSelected = new ActionBehaviorSubject<number>((new Date()).getFullYear());

    // LOGGED USER
    loggedUser = new ActionBehaviorSubject<IUser>(EMPTY_USER);
    loggedUserPermission = new ActionBehaviorSubject<string[]>([]);
    loggedUserClientInfo = new ActionBehaviorSubject<IClientInfo|null>(null);
    getLoggedUser() {
        return this.loggedUser.get();
    }
    userCan(action: APP_PERMISSION_KEY): boolean {
        return this.loggedUserPermission.get().some(x => x === action);
    }

    private progressBar = new BehaviorSubject<string|null>(null);
    progressBar$ = this.progressBar.asObservable();
    setProgressBar(msg: string|null = null) {
        setTimeout(() => {
            this.progressBar.next(msg);
        });
    }

    private error = new BehaviorSubject<string|null>(null);
    error$ = this.error.asObservable();
    nextError(err: string) {
        this.error.next(err);
    }
    clearError() {
        this.error.next(null);
    }

}
