import { AbstractControl } from "@angular/forms";

export interface IPasswordContraints {
    min: boolean;
    max: boolean;
    low: boolean;
    upp: boolean;
    num: boolean;
}

export const EMPTY_PASSWORD_CONSTRAINTS: IPasswordContraints = { min: false, max: false, low: false, upp: false, num: false };

export function evalPasswordContraints(pwd: string): IPasswordContraints {
    const pwd_constraints: IPasswordContraints = {
        min: pwd.length >= 8,
        max: pwd.length <= 16,        
        low: new RegExp('[a-z]+').test(pwd),
        upp: new RegExp('[A-Z]+').test(pwd), 
        num: new RegExp('[0-9]+').test(pwd),
    };
    return pwd_constraints;
}

export function pwdValidator(c: AbstractControl): {[key: string]: string | boolean } | null {
    let pwd = c.value;
    if(!pwd) {
        return null;
    }

    if(pwd.length < 8) {
        return { 'password less than 8': true };
    }
    if(pwd.length > 16) {
        return { 'password greater than 8': true };
    }
    const low = new RegExp('[a-z]+').test(pwd);
    const upp = new RegExp('[A-Z]+').test(pwd); 
    const num = new RegExp('[0-9]+').test(pwd);
    
    if(!low) {
        return { 'at leats 1 letter lowercase': true };
    }
    if(!upp) {
        return { 'at leats 1 letter uppercase': true };
    }
    if(!num) {
        return { 'at leats 1 number': true };
    }

    return null;
}