import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {Clipboard} from '@angular/cdk/clipboard';

@Component({
    templateUrl: './how-to-pay-dialog.component.html',
})
export class HowToPayDialogComponent {

    showCancel: boolean = true;

    constructor(
        public dialogRef: MatDialogRef<HowToPayDialogComponent>,
        private clipboard: Clipboard,
    ) {
    }

    copy_IBAN(): void {
        this.clipboard.copy('IT50Q0503431880000000180413');
    }

    ok(): void {        
        this.dialogRef.close(true);
    }

}
