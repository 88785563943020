<h1 mat-dialog-title>
    Come pagare
</h1>

<div mat-dialog-content>
    Il pagamento della tariffa stabilita si attua con le seguenti modalit&agrave;:
    <ul>
        <li>versamento della caparra al momento dell&apos;iscrizione dell&apos;attivit&agrave; prescelta</li>
        <li>versamento del saldo a conferma di ciascuna attivit&agrave; prescelta con congruo anticipo in:</li>
        <ul>
            <li>contanti </li>
            <li>assegno</li>
            <li>bancomat</li>
            <li>bonifico bancario</li>                    
        </ul>
    </ul>
    <div class="mt-2 d-flex flex-column">
        <div class="d-flex flex-row justify-content-between align-items-center">
            <span>IBAN</span>
            <button mat-icon-button (click)="copy_IBAN()">
                <mat-icon>
                    content_copy
                </mat-icon>
            </button>
        </div>
        <div>
            IT50Q0503431880000000180413
        </div>
        <div class="mt-2">
            INTESTATARIO: 
            SMILEVIL SCS ONLUS
        </div>
    </div>
    
</div>

<div class="d-flex flex-row-reverse mt-3 mb-4 me-4">
    <button mat-flat-button color="primary" (click)="ok()" cdkFocusInitial>OK</button>
</div>